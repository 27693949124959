import React from "react"
import Button from "../../components/button"
import img from "../../images/image 15.jpg"
import { Link } from "gatsby"
import Layout from "../../layout/layout"
import pdfLink from "../../../static/2025 English GST Catalog.pdf"
import pdfIcon from "../../images/pdf-file.svg"
// import Button from "../components/button"
import "../../styles/homestay.css"
const MasterOfDivinity = () => {
  return (
    <Layout
      title="Homestay"
      description=""
    >
      <div className="layout-container homestay-container" style={{marginBottom:"0px"}}>
        <div className="content">
        <div className="qualification-crumbs">
            Global University / Master of Divinity
          </div>
          <div className="InterculturalStudies section1" style={{display:"flex", gap:"80px", justifyContent:"center", alignItems:"start"}}>
            <div style={{width:"50%"}}>
              <h4>Master of Divinity</h4>
              <p className="p-lg">The Master of Divinity (78 credits) is regarded as the first professional degree in ministerial and biblical studies and is designed to prepare students for full-time Christian service: pastoral ministry, church administration, or teaching at the Bible institute or baccalaureate level. Special attention is given to preparing students to teach and proclaim the uniqueness of the Christian faith from a Pentecostal perspective.
              </p>
              <p className="p-lg">The master of divinity degree program allows students to choose to follow two distinct tracks:<br></br>
              Biblical Language Track<br></br>
              Christian Ministry (English Language) Track
              </p>
            <div style={{marginTop:"30px"}}>
              <Link to="/contact">
                <Button>Contact us</Button>
              </Link>
            </div>
              </div>
                <div style={{width:"50%"}}>
                  <img src={img} alt="img" style={{marginTop:"40px",width:"100%"}}/>
                  <div style={{display:"flex", gap:"15px", justifyContent:"center", alignItems:"center", paddingTop:"20px"}}>
                  <a href={pdfLink} target="blank" ><img src={pdfIcon} alt="img" style={{margin:"0px", width:"40px"}}/></a>
                  <a href={pdfLink} target="blank" ><p className="p-md" style={{fontSize:"15px",textDecoration:"underline"}}>2025 Global University, Undergraduate School of Bible and Theology Catalog</p></a>
                </div>
                </div>
              </div>
        </div>
      </div>
      
    </Layout>
  )
}

export default MasterOfDivinity
